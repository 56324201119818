<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Privacy Policy</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <section data-particle_enable="false" data-particle-mobile-disabled="false"
            class="elementor-section elementor-top-section elementor-element elementor-element-29b759a3 ang-section-padding-no elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-repeater-item-none elementor-repeater-item-none_hover jet-parallax-section"
            data-id="29b759a3" data-element_type="section"
            data-settings="{&quot;jet_parallax_layout_list&quot;:[{&quot;jet_parallax_layout_image&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;_id&quot;:&quot;be12c79&quot;,&quot;jet_parallax_layout_image_tablet&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;jet_parallax_layout_image_mobile&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;jet_parallax_layout_speed&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:50,&quot;sizes&quot;:[]},&quot;jet_parallax_layout_type&quot;:&quot;scroll&quot;,&quot;jet_parallax_layout_z_index&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_x&quot;:50,&quot;jet_parallax_layout_bg_y&quot;:50,&quot;jet_parallax_layout_bg_size&quot;:&quot;auto&quot;,&quot;jet_parallax_layout_animation_prop&quot;:&quot;transform&quot;,&quot;jet_parallax_layout_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;],&quot;jet_parallax_layout_direction&quot;:null,&quot;jet_parallax_layout_fx_direction&quot;:null,&quot;jet_parallax_layout_bg_x_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_x_mobile&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_y_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_y_mobile&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_size_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_size_mobile&quot;:&quot;&quot;}]}">
            <div
                class="jet-parallax-section__layout elementor-repeater-item-be12c79 jet-parallax-section__scroll-layout">
                <div class="jet-parallax-section__image"
                    style="background-position: 50% 50%; background-image: url(&quot;&quot;); transform: translateY(5.9px);">
                </div>
            </div>
            <div class="elementor-container elementor-column-gap-default">
                <article
                    class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6765ce4b elementor-repeater-item-none elementor-repeater-item-none_hover"
                    data-id="6765ce4b" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-31d43fd6 elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-heading animated fadeInDown"
                            data-id="31d43fd6" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;_animation_delay&quot;:400}"
                            data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <div class="elementor-heading-title elementor-size-default">Last Updated: June 26, 2023
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-1388f3f3 animated-fast elementor-widget-divider--view-line elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-divider animated slideInUp"
                            data-id="1388f3f3" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;slideInUp&quot;,&quot;_animation_delay&quot;:600}"
                            data-widget_type="divider.default">
                            <div class="elementor-widget-container">
                                <div class="elementor-divider">
                                    <span class="elementor-divider-separator">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-6b58d061 elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-text-editor"
                            data-id="6b58d061" data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                                <p>We at Seek Hire LLC (“Seekmate,” “we,” “us,” “our”) take your
                                    privacy seriously and we are committed to respecting and protecting your privacy.
                                </p>
                                <p>This Privacy Policy describes how we collect and use your Personal Information when
                                    you interact with us, such as when you use our website (<a
                                        href="http://www.seekmate.net" target="_blank" rel="noopener"
                                        style="cursor: pointer;">www.seekmate.net</a>), visit our social media
                                    sites, or use an application provided through our services. If you have any
                                    questions or concerns regarding our privacy practices, please contact us at <a
                                        href="mailto:contact@seekmate.net"
                                        style="cursor: pointer;">contact@seekmate.net</a>.</p>
                                <p>This policy does not apply to websites or practices of companies that Seekmate does
                                    not own or control, or to individuals that Seekmate does not employ or manage,
                                    including providers of third-party software or content.</p>
                                <h3>INFORMATION WE DISPLAY OR COLLECT</h3>
                                <p><strong>Information You Provide to Us </strong></p>
                                <p>We receive and store any information you knowingly enter on the Services, whether via
                                    computer, mobile phone, other wireless device, or that you provide to us in any
                                    other way. This includes information provided in your registration process or that
                                    you may submit for the purpose of paying for our Services. This information may
                                    include, without limitation, personal information such as your name, user name,
                                    email address, phone number, billing and financial information corresponding method
                                    of payment (e.g. a credit card number and expiration date or a bank account number),
                                    and any other information necessary for us to provide our Services (“Personal
                                    Information”).</p>
                                <p><strong>Information Collected Automatically </strong></p>
                                <p>We receive and store certain types of usage information whenever you interact with
                                    the Services; this information is not Personal Information. For example, Seekmate
                                    automatically receives and records information on our server logs from your browser
                                    including your IP address, Seekmate cookie information, and the page you requested.
                                    We also record the details of your activity on the Services, and the number and
                                    frequency of visitors to our site and its components.</p>
                                <p>Cookies are alphanumeric identifiers that we transfer to your computer or mobile
                                    device to enable our systems to recognize your computer or device and tell us how
                                    and when pages in our site are visited and by how many people. Seekmate cookies do
                                    not collect Personal Information, and we do not combine the general information
                                    collected through cookies with other Personal Information to tell us who you are or
                                    what your username or email address is. Most browsers have an option for turning off
                                    the cookie feature, which will prevent your browser from accepting new cookies, as
                                    well as (depending on the sophistication of your browser software) allowing you to
                                    decide on acceptance of each new cookie in a variety of ways. We strongly recommend
                                    that you leave the cookies activated, however, because you may not be able to log in
                                    or use many of the Services’ most attractive features without cookies enabled. The
                                    Services may also contain electronic images known as web beacons (sometimes called
                                    single-pixel gifs) and are used along with cookies to compile aggregated statistics
                                    to analyze how the Services are used.</p>
                                <h3>WHAT WE MAY USE IT FOR</h3>
                                <p><strong>Generally </strong></p>
                                <p>When you use the Services, you may set up your personal profile, send messages,
                                    perform searches and queries, and transmit information through various channels,
                                    depending on the category of user (“User Category”) you are registered as, and as
                                    permitted by the functionality of the Services. The information we gather from users
                                    enables us to personalize and improve our services and allows users to set up a user
                                    account and profile through the Services.</p>
                                <p><strong>Personal Information</strong></p>
                                <p>The Personal Information you provide is used for such purposes as responding to your
                                    requests for certain information and services, customizing your experience, and
                                    communicating with you about the Services. We also use Personal Information for
                                    creating your individual account, customizing your experience, and for sending you
                                    notifications via the Services (“Notifications”).</p>
                                <p><strong>Payment Information </strong></p>
                                <p>Your account and payment information will be used for billing and payment purposes
                                    by Seekmate and by any third-party payment services provider we may engage to manage
                                    and process payments for our customers. By using our Services, you authorize us to
                                    sub-contract in this manner on your behalf. If your details (as the account holder)
                                    are different to the billing contact listed for your account, we may disclose your
                                    identity and account details to the billing contact upon their request (such as an
                                    account administrator under an enterprise account). By using our services and
                                    agreeing to this privacy policy, you consent to this disclosure.</p>
                                <p><strong>Your email address </strong></p>
                                <p>If you provide us your email address, we may send you email communications. Web
                                    beacons may also be used in some of our emails to let us know which emails (and
                                    which links within those emails) have been opened by recipients. This allows us to
                                    gauge the effectiveness of our customer communications. To opt out of receiving
                                    emails from us, please contact us at <a href="mailto:contact@seekmate.net"
                                        style="cursor: pointer;">contact@seekmate.net</a>. Please note that if you do
                                    not want to receive legal notices from us via email, such as this Privacy Policy,
                                    those legal notices will still govern your use of the Services, and you are
                                    responsible for visiting this Privacy Policy from time to time to review any
                                    changes.</p>
                                <p><strong>Usage Information </strong></p>
                                <p> Seekmate uses this type of aggregate data to enable us to figure out how often users
                                    use parts of the Services, so that we can make the Services appealing and relevant
                                    to as many users as possible and customize and improve those Services. As part of
                                    our use of information, we may provide aggregate information to our partners (in a
                                    non-personally identifiable format) about how our users use our site. We share this
                                    type of statistical data so that our partners also understand how often people use
                                    our partners’ services and the Services to help provide you with an optimal online
                                    experience. We may link usage information to Personal Information that we collect
                                    through the Services, but we will only use this linked information internally (for
                                    example, to customize your experience), and will not disclose it in linked format to
                                    third parties.</p>
                                <p><strong>Resale of Personal Information </strong></p>
                                <p>We neither rent nor sell your Personal Information; we may share your Personal
                                    Information and – in personally identifiable form only as described below. Again,
                                    please note that references to “Personal Information”.</p>
                                <p><strong>Businesses We Do Not Control </strong></p>
                                <p>In certain situations, businesses or third- party websites we’re affiliated with may
                                    offer or sell items or provide services to you through the Services (either alone or
                                    jointly with us). We may, for example, work jointly with other businesses to offer
                                    or sell products or provide services, or we may work with third party websites to
                                    enhance your online experience. These transactions or services may or may not be
                                    commercial in nature. You can recognize when such a business is associated with such
                                    a transaction or service. We will share your Personal Information with that business
                                    only to the extent that it is related to such transaction or service. Such services
                                    may include the ability for you to automatically transmit information you input on
                                    the Services to your profile or account on a third party website, or to
                                    automatically transmit information you input on a third party website to your
                                    Services profile. We have no control over the policies and practices of third party
                                    websites as to privacy or anything else, so please review all third party websites’
                                    policies before disclosing any Personal Information or other content on the Services
                                    or on any third party website (via the automatic transmissions discussed above or
                                    otherwise). Seekmate does prohibit Publishers from collecting or using any
                                    information beyond what Seekmate itself collects and uses pursuant to its Privacy
                                    Policy, but this does not mean we are responsible for reviewing or verifying the
                                    accuracy of any Publisher’s data collection or use policies.</p>
                                <p><strong>Agents </strong></p>
                                <p>We employ other companies and people to perform tasks on our behalf and need to share
                                    your information with them to provide products or services to you. Examples include
                                    sending email, analyzing data, processing payment, and providing user services.
                                    Unless we tell you differently, Seekmate’s agents do not have any right to use
                                    Personal Information we share with them beyond what is necessary to assist us.</p>
                                <p><strong>Business Transfers </strong></p>
                                <p>If Seekmate, or some all of its assets were acquired or otherwise transferred, or in
                                    the unlikely event that Seekmate goes out of business or enters bankruptcy, user
                                    information may be transferred to or acquired by a third party.</p>
                                <p><strong>Protection of Seekmate and Others </strong></p>
                                <p>We may release Personal Information when we believe in good faith that release is
                                    necessary to comply with the law (such as to comply with a subpoena); enforce or
                                    apply our Terms of Service and other agreements; or protect the rights, property, or
                                    safety of Seekmate, our employees, our users, or others. We will use our best
                                    efforts
                                    to give you notice if we release information for these reasons, but we reserve the
                                    right not to so if it imposes an undue burden or expense, requires us to release
                                    confidential commercial information, or requires the disclosure of information
                                    relating to another person.</p>
                                <p><strong>Security </strong></p>
                                <p>Your Seekmate account Personal Information is protected by a password for your
                                    privacy
                                    and security. We also use coding practices which take steps to prevent attack on our
                                    Services from web browsers and malicious scripts, by processing all actions through
                                    several permission verifications checks.</p>
                                <p>You may help protect against unauthorized access to your account and Personal
                                    Information by selecting and protecting your password appropriately and limiting
                                    access to your computer and browser by signing off after you have finished accessing
                                    your account.</p>
                                <p>When you enter sensitive information (such as a credit card number) in our Credit
                                    purchasing process, we encrypt the transmission of that information using secure
                                    socket layer technology (SSL).</p>
                                <p> Seekmate endeavors to keep your information private; however, we cannot guarantee
                                    security. Unauthorized entry or use, hardware or software failure, and other factors
                                    may compromise the security of user information. For additional information about
                                    the security measures we use in connection with the Services, please contact us at
                                    <a href="mailto:contact@seekmate.net"
                                        style="cursor: pointer;">contact@seekmate.net</a>.
                                </p>
                                <p><strong>Opt Out Policy </strong></p>
                                <p>You can always opt not to disclose information, even though it may be needed to take
                                    advantage of certain of our features.</p>
                                <p>You may request deletion of your Seekmate account by contacting us at <a
                                        href="mailto:contact@seekmate.net"
                                        style="cursor: pointer;">contact@seekmate.net</a>. Please note that some
                                    unrevised information may remain in our records after revision of such information
                                    or deletion of your account, or in cached and archived pages. Some information may
                                    remain viewable elsewhere to the extent that it was copied or stored by other users.
                                    We may use any aggregated data derived from or incorporating your Personal
                                    Information after you delete your information, but not in a manner that would
                                    identify you personally.</p>
                                <p>We will retain your Personal Information for as long as your account is active or as
                                    long as needed to provide the Services after which time it shall be deleted, subject
                                    to our right to retain and use such Personal Information necessary to comply with
                                    our legal obligations, resolve disputes, and to enforce our agreements.</p>
                                <p><strong>Policy Changes </strong></p>
                                <p>We may make changes to this Privacy Policy from time to time for any reason. We will
                                    provide notification of the material changes to this Privacy Statement
                                    through our website at least thirty (30) business days prior to the change taking
                                    effect. Use of information we collect is subject to the Privacy Policy in effect at
                                    the time such information is collected. Users are bound by any changes to the
                                    Privacy Policy when he or she uses the Services.</p>
                                <p><strong>Children </strong></p>
                                <p> Seekmate is not directed at children under 16. If a parent or guardian becomes aware
                                    that their child has provided us with information without their consent, they should
                                    contact us at <a href="mailto:contact@seekmate.net"
                                        style="cursor: pointer;">contact@seekmate.net</a>, and we will delete such
                                    information as soon as reasonably practicable.</p>
                                <p><strong>Application Outside the United States </strong></p>
                                <p> Seekmate adheres to the principles of Regulation (EU) 2016/679 of the European
                                    Parliament and of the Council of 27 April 2016, as amendment (commonly known as the
                                    General Data Protection Regulations, or “GDPR”) for personal data that it receives
                                    from the EU member countries. If you are a citizen of an EU member country and we
                                    received personal data about you, you have the following rights and options in
                                    addition to those explained elsewhere in this policy:</p>
                                <ul>
                                    <li><strong>Access.</strong> You have the right to ask Seekmate whether it has
                                        received personal information about you from the EU and, if so, what data it has
                                        received. We will attempt in good faith to respond to your request, but we may
                                        not be able to provide the requested information in all situations. For example,
                                        we may not be able to provide the information that you request if it imposes an
                                        undue burden or expense, requires us to release confidential commercial
                                        information, or requires the disclosure of information relating to another
                                        person.</li>
                                    <li><strong>Correction.</strong> You have the right to ask Seekmate to correct the
                                        personal information that we receive about you from the EU. Although we will
                                        attempt in good faith to respond to requests to correct information, we may not
                                        be able to make the correction in all situations. For example, we may not be
                                        able to correct information about you if it would impose an undue burden or
                                        expense or require us to change information relating to another person.</li>
                                    <li><strong>Onward transfer.</strong> As discussed above, Seekmate may share
                                        information with third parties that provide us with services (for example, a
                                        payment processor). Seekmate will take appropriate measures to ensure that the
                                        recipient protects your personal information adequately in accordance with this
                                        Privacy Policy as required by applicable law. These measures include entering
                                        into European Commission approved standard contractual arrangements with them or
                                        ensuring they have signed up to the EU-US Privacy Shield.</li>
                                    <li><strong>Data Portability. </strong>You have the right to receive the personal
                                        data concerning you, in a ‘commonly used and machine-readable format’, and you
                                        have the right to transmit that data to another controller.</li>
                                    <li><strong>Right to be Forgotten. </strong>The right to be forgotten entitles you
                                        to have Seekmate erase your personal data, cease further dissemination of the
                                        data, and potentially have third parties halt processing of the data.</li>
                                    <li><strong>Inquiries and Complaints:</strong> Seekmate has committed to attempt to
                                        resolve privacy complaints under the GDPR principles. You may direct any
                                        inquiries or complaints concerning our GDPR compliance to <a
                                            href="mailto:contact@seekmate.net"
                                            style="cursor: pointer;">contact@seekmate.net</a>. We will respond within 30
                                        days.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    </div>
</section>