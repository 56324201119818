<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area pt-100">
    <div class="container">
        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>About Job</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>How do I apply for a job on Seekmate?</a>
                            <p>
                                To apply for a job on Seekmate, simply create an account, upload your resume, and browse
                                available job listings. Once you find a job that interests you, follow the application
                                instructions provided by the employer.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a>What industries and job types does Seekmate cover?</a>
                            <p>
                                Seekmate covers a wide range of industries and job types, including healthcare,
                                hospitality, customer service, food delivery, and retail sales
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>Can I search for remote job opportunities on Seekmate?</a>
                            <p>
                                Yes, you can search for remote job opportunities on Seekmate by using the appropriate
                                filters during your job search.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>How can I optimize my job search on Seekmate?</a>
                            <p>
                                To optimize your job search on Seekmate, use advanced search functionality to sort
                                results by company size, revenue, experience level, and education. Additionally, set up
                                job alerts to receive notifications about new job postings that
                                match your preferences.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>How do I set up job alerts on Seekmate?</a>
                            <p>
                                To set up job alerts on Seekmate, create an account and customize your job preferences.
                                You will then receive email notifications when new job listings match your criteria.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row faq-wrap">
            <div class="col-lg-12">
                <div class="faq-head">
                    <h2>About Us</h2>
                </div>

                <div class="faq-item">
                    <ul class="accordion">
                        <li class="wow fadeInUp" data-wow-delay=".3s">
                            <a>What is Seekmate's mission and vision?</a>
                            <p>
                                Seekmate's mission is to simplify the job search process, provide valuable resources,
                                and foster a supportive community for professionals to thrive in their careers.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".4s">
                            <a>How does Seekmate ensure a diverse and inclusive community?</a>
                            <p>
                                Seekmate ensures a diverse and inclusive community by promoting equal opportunities for
                                all job seekers and employers, regardless of their background, race, gender, or other
                                factors.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".5s">
                            <a>What resources does Seekmate provide for job seekers and employers?</a>
                            <p>
                                Seekmate provides resources such as job listings, career advice, and networking
                                opportunities for job seekers and employers.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".6s">
                            <a>How does Seekmate protect user privacy and data security?</a>
                            <p>
                                Seekmate protects user privacy and data security by implementing strict security
                                measures and adhering to data protection regulations.
                            </p>
                        </li>

                        <li class="wow fadeInUp" data-wow-delay=".7s">
                            <a>How can I contact Seekmate for support or inquiries?</a>
                            <p>
                                To contact Seekmate for support or inquiries, visit the "Contact Us" section on the
                                website and submit your query through the provided form or email address.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>