<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Contact Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-form-area ptb-100">
    <div class="container-fluid">
        <form id="contactForm">
            <div class="row contact-wrap">
                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="name" id="name" class="form-control" required
                            placeholder="Your Full Name">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="email" name="email" id="email" class="form-control" required
                            placeholder="Your Email">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="phone_number" id="phone_number" required class="form-control"
                            placeholder="Your Phone">
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="form-group">
                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                            placeholder="Subject">
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="form-group">
                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required
                            placeholder="Job Description"></textarea>
                    </div>
                </div>

                <div class="col-md-12 col-lg-12">
                    <div class="text-center">
                        <button type="submit" class="btn contact-btn">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="map-area pb-100">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.7633098946735!2d-118.27707212376141!3d34.04994211785092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7bd35775043%3A0xe6c582c6c9b161be!2s1605%20W%20Olympic%20Blvd%2C%20Los%20Angeles%2C%20CA%2090015%2C%20EE.%20UU.!5e0!3m2!1ses!2ses!4v1683365748035!5m2!1ses!2ses"
        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>