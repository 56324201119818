<footer>
    <div class="container">
        <div class="subscribe-area">
            <div class="section-title">
                <h2>Subscribe Newsletter</h2>
            </div>

            <div class="subscribe-shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/2.png" alt="Shape">
            </div>

            <div class="subscribe-item">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required
                        autocomplete="off">
                    <button class="btn subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>Empowering Your Career Journey: Connect, discover opportunities, and thrive with our global
                            community and user-friendly job search platform.</p>
                        <!-- <ul>
                            <li>
                                <a href="https://www.linkedin.com/company/seekmate/" target="_blank"><i
                                        class="icofont-linkedin"></i></a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <li><a routerLink="/faq"><i class="icofont-simple-right"></i> FAQ</a></li>
                            <li><a routerLink="/contact"><i class="icofont-simple-right"></i> Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-location-pin"></i> 7017 S Priest Dr Apt 1079, TEMPE, AZ, 85283, Maricopa County, USA
                            </li>
                            <li><i class="icofont-ui-call"></i> <a href="tel:+14242706344">+1 424 270 6344</a></li>
                            <li><i class="icofont-ui-email"></i> <a
                                    href="mailto:contact@seekmate.net">contact@seekmate.net</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>Copyright © 2023 Seekmate</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <ul>
                            <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                            <li><span>-</span></li>
                            <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>