







<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Jobs <i
                                    class="icofont-simple-down"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="https://primeapplicants.com/careers/x3f1z8qf46odqxlaglphgw" target="_blank" class="nav-link">Job List</a>
                                </li>

                                <li class="nav-item"><a routerLink="/post-a-job" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Post A
                                        Job</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">FAQ</a>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>

                    <div class="common-btn">
                        <a class="login-btn" href="/login"><i class="icofont-plus-square"></i> Login</a>
                        <a class="sign-up-btn" href="/create-account"><i class="icofont-user-alt-4"></i> Sign
                            Up</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>