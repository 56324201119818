<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Create Account</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Create Account</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="create-account-area pt-100 pb-100">
    <div class="container">
        <div class="create-photo">
            <div class="already-create">
                <span>Already create an account?</span>
                <a routerLink="/login">Sign In</a>
            </div>

            <form>
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <div class="create-photo-item">
                            <div class="create-photo-left">
                                <div class="d-table">
                                    <div class="d-table-cell">
                                        <div class="form-group">
                                            <i class="icofont-photobucket"></i>
                                            <input type="file" class="form-control-file">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <div class="create-photo-item">
                            <div class="create-photo-right">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your Name Here">
                                </div>

                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Profession">
                                </div>

                                <div class="text-right">
                                    <button type="submit" class="btn create-photo-btn">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-information">
            <form>
                <h3>Basic Information</h3>

                <div class="create-information-btn">
                    <a routerLink="/">Upload Cover Photo</a>
                    <a routerLink="/">Upload Your CV</a>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Your Name</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Your Email</label>
                            <input type="email" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Date of Birth</label>
                            <input type="date" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Your Phone</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Job Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Address:</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <div class="gender-area">
                                <span>Gender</span>
                                <input type="radio" name="gender" id="male" value="male" checked>
                                <label for="male">Male</label>
                                <input type="radio" name="gender" id="female" value="female">
                                <label for="female">Female</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Description</label>
                            <textarea id="your_message" class="form-control" rows="8"></textarea>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-education">
            <div class="create-education-wrap">
                <div class="create-education-left">
                    <h3>Education</h3>
                </div>

                <div class="create-education-right">
                    <a routerLink="/">Add Education</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Degree</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Institute</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Year</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Skill</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add Skill</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="skill">
                            <p>Percentage</p>
                            <div class="skill-bar skill1 wow slideInLeft animated">
                                <span class="skill-count1">70%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="create-skills">
            <div class="create-skills-wrap">
                <div class="create-skills-left">
                    <h3>Social Links</h3>
                </div>

                <div class="create-skills-right">
                    <a routerLink="/">Edit</a>
                    <a routerLink="/">Add New</a>
                </div>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Facebook</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Instagram</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Linedin</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Dribbble</label>
                            <input type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </form>
        </div>
        
        <div class="text-left">
            <button type="submit" class="btn create-ac-btn">Save</button>
        </div>
    </div>
</div>