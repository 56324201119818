<div class="banner-area banner-img-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Find Your <span>Desire</span> Job</h1>
                    <p>Jobs, Employment & Future Career Opportunities</p>
                    <a href="https://primeapplicants.com/careers/x3f1z8qf46odqxlaglphgw" target="_blank" class="btn banner-form-btn">APPLY NOW</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="account-area">
    <div class="container">
        <div class="row account-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-approved"></i>
                    <span>Register Your Account</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="account-item">
                    <i class="flaticon-cv"></i>
                    <span>Upload Your Resume</span>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="account-item account-last">
                    <i class="flaticon-customer-service"></i>
                    <span>Apply for Dream Job</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="category-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Choose Your Desire Category</h2>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item">
                    <i class="flaticon-settings"></i>
                    Technical Support
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-two">
                    <i class="flaticon-layers"></i>
                    Business Development
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-three">
                    <i class="flaticon-house"></i>
                    Real Estate Business
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-four">
                    <i class="flaticon-analysis"></i>
                    Share Maeket Analysis
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-three">
                <div class="category-item category-five">
                    <i class="flaticon-sun"></i>
                    Weather & Environment
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-six">
                    <i class="flaticon-hand"></i>
                    Finance & Banking Service
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-seven">
                    <i class="flaticon-neural"></i>
                    IT & Networing Sevices
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-eight">
                    <i class="flaticon-dish"></i>
                    Restaurant Services
                </div>
            </div>

            <div class="col-sm-3 offset-sm-3 offset-lg-0 col-lg-3 category-border-two">
                <div class="category-item category-nine">
                    <i class="icofont-fire-burn"></i>
                    Defence & Fire Service
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="category-item category-ten">
                    <i class="flaticon-truck"></i>
                    Home Delivery Services
                </div>
            </div>
        </div>
    </div>
</section>

<div class="portal-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-12">
                            <img src="assets/img/home-1/1.jpg" alt="Portal">
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right">
                    <h2>Trusted & Popular Job Portal</h2>
                    <p>Reliable platform connecting job seekers with top employers, user-friendly interface, accurate
                        job listings, and global community.</p>
                    <div class="common-btn">
                        <a class="login-btn" routerLink="/post-a-job">Post a Job <i
                                class="icofont-swoosh-right"></i></a>
                        <a class="sign-up-btn" href="https://primeapplicants.com/careers/x3f1z8qf46odqxlaglphgw" target="_blank">Apply Now <i
                                class="icofont-swoosh-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Recent Jobs</h2>
        </div>

        <div class="sorting-menu">
            <ul>
                <li class="filter active" data-filter="all">All</li>
                <li class="filter" data-filter=".web">New</li>
                <li class="filter" data-filter=".ui">Featured</li>
                <li class="filter" data-filter=".branding">Recent</li>
                <li class="filter" data-filter=".ux">Full Time</li>
                <li class="filter" data-filter=".wp">Part Time</li>
            </ul>
        </div>

        <div id="container">
            <div class="row">
                <div class="col-lg-6 mix web ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/1.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>UI/UX Designer</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Full Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ui web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/2.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Android Developer</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix branding wp">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/3.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Senior Manager</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Intern</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ux branding">
                    <div class="job-item ">
                        <img src="assets/img/home-1/jobs/4.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Product Designer</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix wp web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/5.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Digital Marketer</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Intern</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix web ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/6.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Sales Manager</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix ux ui">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/7.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>Web Developer</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Full Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mix branding web">
                    <div class="job-item">
                        <img src="assets/img/home-1/jobs/8.png" alt="Job">

                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3>SEO</h3>
                                <ul>
                                    <li><i class="icofont-money-bag"></i>$20k - $25k</li>
                                    <li><i class="icofont-location-pin"></i>Location 210-27 Quadra, Market Street,
                                        Victoria Canada</li>
                                </ul>
                            </div>

                            <div class="job-inner-right">
                                <ul>
                                    <li><a routerLink="/create-account">Apply</a></li>
                                    <li><span>Part Time</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="popular-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <img src="assets/img/home-1/3.jpg" alt="Popular">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>Why We are Most Popular</h2>
                    </div>
                    <p>User-friendly platform, diverse job listings, trusted by professionals, advanced search features,
                        and global community.</p>

                    <div class="row popular-wrap">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                <li><i class="flaticon-employee"></i> International Job</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="app-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="app-item app-left">
                    <img src="assets/img/home-1/6.png" alt="Mobile">
                    <img src="assets/img/home-1/7.png" alt="Mobile">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="app-item">
                    <div class="section-title text-start">
                        <h2>Mobile applications coming soon</h2>
                    </div>
                    <div class="app-btn">
                        <a class="app-btn-one">
                            <i class="flaticon-apple"></i>
                            <span>Download on the</span>
                            <p>App Store</p>
                        </a>
                        <a class="app-btn-two">
                            <i class="flaticon-playstore"></i>
                            <span>ANDROID APP ON</span>
                            <p>Google Play</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>