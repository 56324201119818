<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-12">
                            <img src="assets/img/home-1/1.jpg" alt="Portal">
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="portal-item portal-right portal-right-two">
                    <h2>About Us</h2>
                    <p>
                        Seekmate is a dynamic job search platform that connects job seekers and employers around the
                        world. Our mission is to simplify the job search process, provide valuable resources and foster
                        a supportive community for professionals to thrive in their careers. With advanced technology
                        and a user-centric approach, Seekmate offers an efficient and personalised job search
                        experience. Our platform covers diverse industries and offers both local and remote
                        opportunities, adapting to the changing needs of the job market. In addition, Seekmate prides
                        itself on being an inclusive and diverse community, where professionals can connect, share
                        experiences and learn from each other. Join us today and let Seekmate be your partner on the
                        road to career success.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
<br>