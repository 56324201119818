<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Terms and Conditions</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Terms and Conditions</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <section data-particle_enable="false" data-particle-mobile-disabled="false"
            class="elementor-section elementor-top-section elementor-element elementor-element-2bb94c3 ang-section-padding-no elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-repeater-item-none elementor-repeater-item-none_hover jet-parallax-section"
            data-id="2bb94c3" data-element_type="section"
            data-settings="{&quot;jet_parallax_layout_list&quot;:[{&quot;jet_parallax_layout_image&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;_id&quot;:&quot;be12c79&quot;,&quot;jet_parallax_layout_image_tablet&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;jet_parallax_layout_image_mobile&quot;:{&quot;url&quot;:&quot;&quot;,&quot;id&quot;:&quot;&quot;,&quot;size&quot;:&quot;&quot;},&quot;jet_parallax_layout_speed&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:50,&quot;sizes&quot;:[]},&quot;jet_parallax_layout_type&quot;:&quot;scroll&quot;,&quot;jet_parallax_layout_z_index&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_x&quot;:50,&quot;jet_parallax_layout_bg_y&quot;:50,&quot;jet_parallax_layout_bg_size&quot;:&quot;auto&quot;,&quot;jet_parallax_layout_animation_prop&quot;:&quot;transform&quot;,&quot;jet_parallax_layout_on&quot;:[&quot;desktop&quot;,&quot;tablet&quot;],&quot;jet_parallax_layout_direction&quot;:null,&quot;jet_parallax_layout_fx_direction&quot;:null,&quot;jet_parallax_layout_bg_x_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_x_mobile&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_y_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_y_mobile&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_size_tablet&quot;:&quot;&quot;,&quot;jet_parallax_layout_bg_size_mobile&quot;:&quot;&quot;}]}">
            <div
                class="jet-parallax-section__layout elementor-repeater-item-be12c79 jet-parallax-section__scroll-layout">
                <div class="jet-parallax-section__image"
                    style="background-position: 50% 50%; background-image: url(&quot;&quot;); transform: translateY(8.4px);">
                </div>
            </div>
            <div class="elementor-container elementor-column-gap-default">
                <article
                    class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8ca7042 elementor-repeater-item-none elementor-repeater-item-none_hover"
                    data-id="8ca7042" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-element-06e7714 elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-heading animated fadeInDown"
                            data-id="06e7714" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;fadeInDown&quot;,&quot;_animation_delay&quot;:400}"
                            data-widget_type="heading.default">
                            <div class="elementor-widget-container">
                                <div class="elementor-heading-title elementor-size-default">Last Updated: June 26, 2023
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-676d73d animated-fast elementor-widget-divider--view-line elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-divider animated slideInUp"
                            data-id="676d73d" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;slideInUp&quot;,&quot;_animation_delay&quot;:600}"
                            data-widget_type="divider.default">
                            <div class="elementor-widget-container">
                                <div class="elementor-divider">
                                    <span class="elementor-divider-separator">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-element elementor-element-6458640 elementor-repeater-item-none elementor-repeater-item-none_hover elementor-widget elementor-widget-text-editor"
                            data-id="6458640" data-element_type="widget" data-widget_type="text-editor.default">
                            <div class="elementor-widget-container">
                                <p><strong>THIS FOLLOWING TERMS OF SERVICE DESCRIBES THE TERMS AND CONDITIONS ON
                                        WHICH SEEK HIRE LLC OFFERS YOU USE OF OUR WEBSITE AND ACCESS
                                        TO OUR SERVICES. </strong></p>
                                <p>These Terms of Service (the “Terms”) describe the terms and conditions for your use
                                    of our websites and services and all the other products, services and applications
                                    made available by Seek Hire LLC (the “Services”). You must agree
                                    and accept all of the Terms, or you don’t have the right to use the Services. Your
                                    using the Services in any way means that you agree to all of these Terms, and these
                                    Terms will remain in effect while you use the Services. We reserve the right, at our
                                    sole discretion, to change, modify or otherwise alter these Terms at any time. If
                                    you use the Services in any way after a change to the Terms is effective, then
                                    please remember that means you agree to all of the Terms. Except for changes by us
                                    as described here, no other amendment or modification of these Terms will be
                                    effective unless in writing and signed by both you and us. </p>
                                <p>If you have any questions, comments, or concerns regarding these Terms, please
                                    contact us a <a href="mailto: contact@seekmate.net" style="cursor: pointer;">
                                        contact@seekmate.net</a>. </p>
                                <h3>Registration </h3>
                                <p>As a condition to using Services, you are required to open or have a current an
                                    account with Seekmate and select a password and username (which may be your e-mail
                                    address), and to provide registration information. The registration information you
                                    provide must be accurate, complete, and current at all times. Failure to do so
                                    constitutes a breach of the Terms, which may result in immediate termination of your
                                    account. </p>
                                <p>You may not use as a username the name of another person or entity or that is not
                                    lawfully available for use, a name or trademark that is subject to any rights of
                                    another person or entity other than you without appropriate authorization, or a name
                                    that is otherwise offensive, vulgar or obscene. </p>
                                <p>You are responsible for maintaining the confidentiality of your password and are
                                    solely responsible for all activities resulting from the use of your password and
                                    conducted through your account. </p>
                                <h3>Your Representations </h3>
                                <p>By using our Services including our website(s), you represent that: </p>
                                <p>You represent and warrant that you are of legal age to form a binding contract. </p>
                                <p>If you’re agreeing to these Terms on behalf of an organization or entity, you
                                    represent and warrant that you are authorized to agree to these Terms on that
                                    organization or entity’s behalf and bind them to these Terms. </p>
                                <p>You promise to only use the Services for your internal, non-commercial, business or
                                    educational use, and only in a manner that complies with all laws that apply to you.
                                    If your use of the Services is prohibited by applicable laws, then you aren’t
                                    authorized to use the Services. We can’t and won’t be responsible for you using the
                                    Services in a way that breaks the law. </p>
                                <p>You further agree that your Content that may be uploaded to our website(s) and your
                                    interactions when using the Service shall not: (a) be false, inaccurate or
                                    misleading; (b) infringe any third party’s rights, including but not limited to:
                                    intellectual property rights, copyright, patent, trademark, trade secret or other
                                    proprietary rights or rights of publicity or privacy; (c) violate any law, statute,
                                    ordinance or regulation; (d) be defamatory, trade libelous, abusive, obscene,
                                    profane, offensive, sexually oriented, threatening, harassing, racially offensive or
                                    contain illegal material; (e) contain any offensive anatomical or sexual references,
                                    or offensive sexually suggestive or connotative language; (f) contain any viruses,
                                    Trojan horses, worms, time bombs, cancelbots, Easter eggs or other computer
                                    programming routines that may damage, detrimentally interfere with, surreptitiously
                                    intercept or expropriate any system, data or personal information; or (g) create
                                    liability for us. We reserve the right, but We have no obligation, to reject or
                                    terminate Service to any user that does not comply with these prohibitions. </p>
                                <h3>Responsibility for Content </h3>
                                <p>Any information or Content posted or privately transmitted through the Services is
                                    the sole responsibility of the person from whom such content originated, and you
                                    access all such information and Content at your own risk, and we aren’t liable for
                                    any errors or omissions in that information or content or for any damages or loss
                                    you might suffer in connection with it. We cannot control and have no duty to take
                                    any action regarding how you may interpret and use the Content or what actions you
                                    may take as a result of having been exposed to the Content, and you hereby release
                                    us from all liability for you having acquired or not acquired Content through the
                                    Services. The Services may contain, or direct you to websites containing,
                                    information you may find offensive or inappropriate; and we accept no responsibility
                                    or liability for such activity. If you experience or encounter any inappropriate
                                    material while using the Service, please contact us immediately at <a
                                        href="mailto: contact@seekmate.net" style="cursor: pointer;">
                                        contact@seekmate.net</a> so we can endeavor to remedy
                                    the situation. We also can’t guarantee the identity of any users with whom you
                                    interact in using the Services and are not responsible for which users gain access
                                    to the Services. </p>
                                <p>You are responsible for all Content you upload, contribute or submit, in any manner,
                                    to the Services, and you represent and warrant you have all rights necessary to do
                                    so, in the manner in which you contribute it. You will keep all your registration
                                    information accurate and current. You are responsible for all your activity in
                                    connection with the Services. </p>
                                <h3>Intellectual Property </h3>
                                <p>All materials displayed or performed on the Services (including, but not limited to,
                                    data, text, graphics, articles, photos, images, illustrations, and so forth,
                                    collectively, “Content” herein) are protected by copyright and other intellectual
                                    property laws. You promise to abide by all copyright notices, trademark rules,
                                    information, and restrictions contained in any Content you access through the
                                    Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast,
                                    transmit, distribute, perform, upload, display, license, sell or otherwise exploit
                                    for any purpose any Content not owned by you, (i) without the prior consent of the
                                    owner of that Content or (ii) in a way that violates someone else’s
                                    (including Seekmate’s) rights. </p>
                                <p>You understand that Seekmate owns the Services including, without limitation, all
                                    software used in providing the Services. You won’t modify, publish, transmit,
                                    participate in the transfer or sale of, reproduce (except as expressly provided in
                                    this Section), creative derivative works based on, or otherwise exploit any of the
                                    Services.</p>
                                <p>In connection with your use of the Service or its contents, you will not (a) post,
                                    distribute or reproduce in any way any copyrighted material, trademarks, or other
                                    proprietary information without obtaining the prior consent of the owner of such
                                    proprietary rights; (b) remove any copyright, trademark or other proprietary rights
                                    notices contained in the Seekmate’s software or with respect to the Service; (c)
                                    interfere with or disrupt the Services or the site or the servers or networks
                                    connected to the Services or the site; (d) post, email or otherwise transmit any
                                    material that contains software viruses or any other computer code, files or
                                    programs designed to interrupt, destroy or limit the functionality of any computer
                                    software or hardware or telecommunications equipment; (e) forge headers or otherwise
                                    manipulate identifiers in order to disguise the origin of any information
                                    transmitted through the Service; (f) “frame” or “mirror” any part of the Service, or
                                    use meta tags or code or other devices containing any reference to us or the Service
                                    or the site in order to direct any person to any other website for any purpose; (g)
                                    modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or
                                    otherwise disassemble any portion of the website or any software used on or for the
                                    Service or cause others to do so; or (h) use the Services in connection with any
                                    commercial endeavors whatsoever without our express prior written consent. </p>
                                <h3>Changes in the Services </h3>
                                <p>We may change, suspend, or discontinue any part of the Services, or we may introduce
                                    new features or impose limits on certain features or restrict access to parts or all
                                    of the Services. Similarly, we reserve the right to remove any Content from the
                                    Services at any time, for any reason (including, but not limited to, if someone
                                    alleges you contributed that Content in violation of these Terms), or without any
                                    reason, and without notice. </p>
                                <p>These Terms apply to your use of all the Services, including the Seekmate Core
                                    software and mobile applications.</p>
                                <h3>Warranty and Disclaimer; Limit of Liability </h3>
                                <p>Your access to and use of the website may be interrupted from time to time as a
                                    result of equipment malfunction, updating, maintenance or repair of the website or
                                    any other reason within or outside the control of Seekmate. We reserve the right to
                                    suspend or discontinue the availability of the website and/or any Service and/or
                                    remove any Content at any time at its sole discretion and without prior notice. We
                                    may also impose limits on certain features and Services or restrict your access to
                                    parts of or all of the website and the Services without notice or liability. The
                                    website should not be used or solely relied upon for storage of your learning data.
                                    Your data is exportable, and you are directed to retain your own copies of all
                                    Content posted on the website. </p>
                                <p>Seekmate does not make any representations or warranties concerning any Content
                                    contained in or accessing through the Services, and we will not be responsible or
                                    liable for the accuracy, copyright compliance, legality, or decency of material
                                    contained in or accessed through the Services. We make no representations or
                                    warranties regarding suggestions or recommendations of services or products offered
                                    or purchased through the Services. Products and services purchased or offered
                                    (whether or not following such recommendations and suggestions) through the Services
                                    are provided “AS IS” and without any warranty of any kind from Seekmate or others
                                    (unless, with respect to such others only, provided expressly and unambiguously in
                                    writing by a designated third party for a specific product). </p>
                                <p><strong>THE SERVICES, CONTENT, WEBSITE, AND ANY SOFTWARE ARE PROVIDED ON AN “AS-IS”
                                        BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                                        WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                        PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE
                                        UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                                        IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. </strong>
                                </p>
                                <p><strong>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND
                                        UNDER NO LEGAL THEORY (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT
                                        LIABILITY, OR OTHERWISE) SHALL SEEKMATE BE LIABLE TO YOU OR TO ANY OTHER PERSON
                                        FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
                                        INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF
                                        RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE
                                        AGGREGATE, IN EXCESS OF THE LESSER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU
                                        TO SEEKMATE IN CONNECTION WITH THE SERVICES IN THE SIX (6) MONTH PERIOD
                                        PRECEDING
                                        THIS APPLICABLE CLAIM, OR (III) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME
                                        STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE
                                        LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU. </strong></p>
                                <h3>Indemnity </h3>
                                <p>You agree to indemnify and hold Seekmate, its affiliates, officers, agents,
                                    employees,
                                    contractors, and partners harmless for and against any and all claims, liabilities,
                                    damages (actual and consequential), losses, and expenses (including attorneys’ fees)
                                    arising from or in any way related to any third party claims relating to (a) your
                                    use of the Services (including any actions taken by a third party using your
                                    account), and (b) your violation of these Terms. In the event of such a claim, suit,
                                    or action (“Claim”), we will provide notice of the Claim to the contact information
                                    we have for your account (provided that failure to deliver such notice shall not
                                    eliminate or reduce your indemnification obligations hereunder). </p>
                                <h3>Assignment </h3>
                                <p>You may not assign, delegate, or transfer these Terms or your rights or obligations
                                    hereunder, or you’re Seekmate Services account, in any way (by operation of law or
                                    otherwise) without Seekmate’s prior written consent. We may transfer, assign, or
                                    delegate these Terms and our rights and obligations without consent. </p>
                                <h3>Choice of Law; Arbitration </h3>
                                <p>These Terms are governed by and will be construed under the laws of the State
                                    of California, without regard to the conflicts of law’s provisions thereof. Any
                                    dispute arising from or relating to the subject matter of these Terms shall be
                                    finally settled in San Bernardino, California, in accordance with the Arbitration
                                    Rules and Procedures of the American Arbitration Association (“AAA”) then in effect,
                                    by one commercial arbitrator with substantial experience in resolving intellectual
                                    property and commercial contract disputes, who shall be selected from the
                                    appropriate list of AAA arbitrators in accordance with the Arbitration Rules and
                                    Procedures of AAA. Judgment upon the award rendered by such arbitrator may be
                                    entered in any court of competent jurisdiction. Notwithstanding the foregoing
                                    obligation to arbitrate disputes, each party shall have the right to pursue
                                    injunctive or other equitable relief at any time, from any court of competent
                                    jurisdiction. For all purposes of this Agreement, the parties consent to exclusive
                                    jurisdiction and venue in the United States Federal Courts located in California.
                                </p>
                                <h3>Term and Termination </h3>
                                <p>These Terms are effective upon your use of the Services and upon the posting dates of
                                    any subsequent amendments to this Agreement for all current users. We may terminate
                                    your access to the Service at any time, for any or no reason, without explanation or
                                    notice of any kind. Upon such termination by us, we may remove all of your
                                    information from our servers. We maintain sole discretion to bar your use of the
                                    Service in the future, for any or no reason. Even after your participation in the
                                    Service is terminated, this Agreement will remain in effect and will continue to
                                    govern, among other things, your right to use the Service including any software or
                                    mobile application. </p>
                                <h3>Breach </h3>
                                <p>Without limiting other remedies, we may terminate your ability to use the Service,
                                    remove your Information, warn our community of your actions, issue a warning, and
                                    refuse to provide our services to you if: (a) you breach these Terms or the
                                    documents it incorporates by reference; (b) we are unable to verify or authenticate
                                    any information you provide to us; (c) we believe that your actions may cause
                                    financial loss or legal liability for you, our users or us; or (d) if we suspect
                                    that you have engaged in fraudulent or illegal activity in connection with the
                                    Services. </p>
                                <h3>Communications </h3>
                                <p>You (a) give consent to receive communications from us in an electronic form via the
                                    email address you have submitted; and (b) agree that all Terms of Use, agreements,
                                    notices, disclosures, and other communications that we provide to you electronically
                                    satisfy any legal requirement that such communications would satisfy if it were in
                                    writing. The foregoing does not affect your non-waivable rights. </p>
                                <p>We may also use your email address to send you other messages, including information
                                    about Seekmate and special offers. You may opt out of such email by changing your
                                    account settings or sending an email to <a href="mailto: contact@seekmate.net"
                                        style="cursor: pointer;"> contact@seekmate.net</a>. Opting out may prevent you
                                    from receiving messages regarding Seekmate or the Services, special announcements or
                                    special offers. </p>
                                <p>Communications made through email or our messaging system will not constitute legal
                                    notice to Seekmate or any of its officers, employees, agents or representatives in
                                    any situation where notice to Seekmate is required by contract or any law or
                                    regulation. </p>
                                <h3>Miscellaneous </h3>
                                <p>You will be responsible for withholding, filing, and reporting all taxes, duties, and
                                    other governmental assessments associated with your activity in connection with the
                                    Services. The failure of either you or us to exercise, in any way, any right herein
                                    shall not be deemed a waiver of any further rights hereunder. If any provision of
                                    this Agreement is found to be unenforceable or invalid, that provision will be
                                    limited or eliminated, to the minimum extent necessary, so that these Terms shall
                                    otherwise remain in full force and effect and enforceable. You and Seekmate agree
                                    that these Terms are the complete and exclusive statement of the mutual
                                    understanding between you and Seekmate, and that it supersedes and cancels all
                                    previous written and oral agreements, communications, and other understandings
                                    relating to the subject matter of these Terms, and that all modifications to these
                                    Terms must be in a writing signed by both parties (except as otherwise provided
                                    herein). No agency, partnership, joint venture, or employment is created as a result
                                    of these Terms and you do not have any authority of any kind to bind Seekmate in any
                                    respect whatsoever. Except as expressly set forth herein, you and Seekmate agree
                                    there are no third-party beneficiaries intended under this Agreement. </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    </div>
</section>